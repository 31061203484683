import React from "react"
import { useTheme } from "emotion-theming"
import Layout from "../components/Layout"
import "../styles/global.css"
import { Section } from "../components/PageComponents/Section"
import SEO from "../components/seo"
import { ContentContainer } from "../components/PageComponents/ContentContainer"
import Accordion from "../components/Accordion/Accordion"

const FormedlingPage = () => {
  const theme = useTheme()

  return (
    <Layout>
      <SEO title="evoli - Om försäkringsförmedling" />
      <Section bgColor={theme.colors.primaryBackground}>
        <ContentContainer>
          <h3>Om försäkringsförmedling</h3>

          <h4>Grundläggande information</h4>

          <p>
            evoli AB är registrerat som försäkringsförmedlare hos
            Finansinspektionen och förmedlar försäkringar på den svenska
            marknaden. evoli har sin ansvarsförsäkring hos Lloyd’s Association
            of Underwriters.
            <br />
            <br />
            evoli är registrerat hos Bolagsverket och står under
            Finansinspektionens och Konsumentverkets tillsyn. I
            Finansinspektionen företagsregister är de rådgivare som är anställda
            hos oss upptagna. Om du vill kontrollera tillstånd och registrering
            för evoli och våra anställa försäkringsförmedlare kan detta göras
            hos Finansinspektionen respektive Bolagsverket, se kontaktuppgifter
            nedan.
            <br />
            <br />
            Försäkringsgivare är Eir Försäkring AB. evoli får ersättning
            från Eir för de förmedlade försäkringarna och den ersättningen
            baseras på den totala premievolymen i kombination med
            försäkringsaffärens tekniska resultat.
            <br />
            <br />
            Försäkringsgivarehar rätt att få tillbaka ersättning från evoli om
            försäkringstagaren avslutar försäkringen i förtid eller slutar att
            betala premien. Detta gäller under hela försäkringstiden och rätten
            till återbetalningen motsvarar den delen av provisionen som är
            hänförlig till den försäkringsperiod som blivit obetalad eller
            annullerad i förtid.
            <br />
            <br />
            Inom ramen för vår tjänst tillhandahåller vi kostnadsfri rådgivning
            om de försäkringsprodukter som vi förmedlar. evolis rådgivning sker
            inte på grundval av en så kallad opartisk och personlig analys. Om
            något särskilt försäkringsmoment eller skydd är viktigt för dig
            måste du informera oss om detta samt kontrollera att detta verkligen
            omfattas av den försäkring du väljer. Alla försäkringsförmedlare på
            evolis kundservice är anställda i företaget och uppfyller de
            formella kraven för att få vara försäkringsförmedlare. evolis
            kundservice har fasta månadslöner och inte provision. På detta sätt
            har de aldrig en fördel att rekommendera en viss lösning framför en
            annan.
            <br />
            <br />
            evoli har tillstånd att förmedla försäkringar inom följande
            försäkringsklasser (enligt Försäkringsrörelselag (2010:2043) 2 kap
            11§): 
            <br />
            <br />
            Direktskadeförsäkring
            <br />
            <br />
            Klass 1. Olycksfall 
            <br />
            Klass 2. Sjukdom
            <br />
            Klass 3. Landfordon (andra än spårfordon)
            <br />
            Klass 6. Fartyg
            <br />
            Klass 7. Godstransport (varor, bagage och allt annat gods)
            <br />
            Klass 8. Brand och naturkrafter
            <br />
            Klass 9. Annan sakskada
            <br />
            Klass 10. Motorfordonansvar
            <br />
            Klass 12. Fartygansvar
            <br />
            Klass 13. Allmän ansvarighet
            <br />
            Klass 16. Annan förmögenhetsskada
            <br />
            Klass 17. Rättsskydd
            <br />
            Klass 18. Assistans
            <br />
          </p>

          <h4>Ansvarsförsäkring</h4>
          <p>
            evoli innehar en ansvarsförsäkring hos RSG Sweden (Lloyd’s
            Association of Underwriters). Krav riktade mot evoli kan riktas
            direkt mot försäkringsgivare för de fall ersättning ej har utgått
            från evoli, claims@rsgsweden.com. Den högsta ersättningen som kan
            betalas ut för en enskild skada är ca 1,25 miljoner euro. Under ett
            år kan ersättning för skador uppgå till som mest ca 5 miljoner euro.
          </p>

          <h4>Tillämpade lagar</h4>
          <p>
            På våra försäkringsavtal tillämpas svensk lag i fråga om
            marknadsföring (Marknadsföringslagen (2008:486)), försäkringsavtalet
            (Försäkringsavtalslagen (2005:104)), försäkringsdistribution (lag
            (2018:1219) om försäkringsdistribution) och distansköp (Lag
            (2005:59) om distansavtal och avtal utanför affärslokal).
          </p>

          <h4>Din ångerrätt</h4>
          <p>
            Om du som konsument har ingått avtal på distans (t.ex. via telefon
            eller internet) har du rätt att ångra dig inom fjorton dagar från
            att du ingått avtalet, du ska då meddela evoli om detta. evoli har
            rätt till den del av försäkringspremien som motsvarar tiden då
            avtalet varit i kraft.
          </p>

          <h4>Vår behandling av dina personuppgifter</h4>
          <p>
            evoli, och i förekommande fall Eir använder personuppgifter i vår
            administration, skadereglering, marknads- och kundanalys,
            produktutveckling, marknadsföring och för statistiska ändamål. Vi
            behandlar uppgifterna i enlighet med Dataskyddsförordningen (GDPR).
            Läs mer om evolis hantering av personuppgifter här.
          </p>

          <h4>Om du inte är nöjd</h4>
          <p>
            Om du har generella klagomål mot evoli gällande till exempel
            försäljning kan du vända dig till vår klagomålsansvarige genom att
            mejla till{" "}
            <a
              href="mailto:klagomal@evoli.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              klagomal@evoli.se
            </a>
            . Du, som konsument, kan också vända dig till Konsumentvägledningen
            i din kommun eller till Konsumenternas Försäkringsbyrå. Vid tvist
            kan du vända dig till Allmänna reklamationsnämnden eller till Allmän
            domstol.
          </p>

          <h4>Om du inte är nöjd med skaderegleringen?</h4>
          <p>
            I första hand ber vi dig kontakta den person som gjort bedömningen
            av din skada.
            <br />
            <br />
            Är du fortfarande inte nöjd kan du kontakta klagomålsanasvarg på{" "}
            <a
              href="mailto:klagomal@eir.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              klagomal@eir.se
            </a>{" "}
            kommer att behöva ditt namn, din adress, ditt skadenummer,
            telefonnummer och såklart en förklaring till varför du inte är nöjd.
            Klagomålsanvarig kommer att gå igenom ärendet och kontakta dig inom
            14 dagar för att informerar dig om hur Eir ser på ärendet. Om vi, av
            någon anledning inte har möjlighet att kontakta dig inom 14 dagar
            kommer vi att åtminstone kontakt dig inom utsatt tid och förklara
            varför vi inte kommer att hinna svar dig och när du kan förvänta dig
            ett svar.
          </p>

          <h4>Viktiga kontaktuppgifter och adresser</h4>
          <br />
          <Accordion
            title="evoli AB"
            content="Org.nr 559180-0445
            <br />
            Kungsgatan 64
            <br />
            111 22 Stockholm
            <br />
            <a href='tel:0660660666' target='_blank' rel='noopener noreferrer'>
            0660-66 06 66
            </a>
            <br />
            <a href='mailto:info@evoli.se' target='_blank' rel='noopener noreferrer'>
            info@evoli.se
            </a>
            <br />
            <a href='https://evoli.se' target='_blank' rel='noopener noreferrer'>
            evoli.se
            </a>"
          />

          <Accordion
            title="Eir Försäkring AB"
            content="Org.nr 559166-0617 
            <br /> 
            Kungsgatan 37, 
            <br /> 
            111 56 Stockholm 
            <br />
            <a href='mailto:info@eir.se' target='_blank' rel='noopener noreferrer'>
            info@eir.se
            </a>
            <br />
            <a href='https://eirforsakring.se' target='_blank' rel='noopener noreferrer'>
            eirforsakring.se
            </a>"
          />

          <Accordion
            title="RSG Sweden (Lloyd’s Association of Underwriters)"
            content="Org.nr 556741-6572
            <br />
            Ingmar Bergmans gata 2
            <br />
            114 34 Stockholm
            <br />
            <a href='tel:0841026881' target='_blank' rel='noopener noreferrer'>
            08-410 268 81
            </a>
            <br />
            <a href='mailto:claims@rsgsweden.com' target='_blank' rel='noopener noreferrer'>
            claims@rsgsweden.com
            </a>
            <br />
            <a href='https://evoli.se' target='_blank' rel='noopener noreferrer'>
            rsgsweden.com
            </a>"
          />

          <Accordion
            title="Bolagsverket"
            content="
            851 81 Sundsvall
            <br />
            <a href='tel:0771670670' target='_blank' rel='noopener noreferrer'>
            0771-670 670
            </a>
            <br />
            <a href='mailto:bolagsverket@bolagsverket.se' target='_blank' rel='noopener noreferrer'>
            bolagsverket@bolagsverket.se
            </a>
            <br />
            <a href='https://bolagsverket.se' target='_blank' rel='noopener noreferrer'>
            bolagsverket.se
            </a>"
          />

          <Accordion
            title="Finansinspektionen"
            content="
            box 7821
            <br />
            10397 Stockholm
            <br />
            <a href='tel:087878000' target='_blank' rel='noopener noreferrer'>
            08-7878000
            </a>
            <br />
            <a href='mailto:finansinspektionen@fi.se' target='_blank' rel='noopener noreferrer'>
            finansinspektionen@fi.se
            </a>
            <br />
            <a href='https://fi.se' target='_blank' rel='noopener noreferrer'>
            fi.se
            </a>"
          />

          <Accordion
            title="Konsumentverket"
            content="
            box 48
            <br />
            651 02 Karlstad
            <br />
            <a href='tel:0771423300' target='_blank'>
            0771-42 33 00
            </a>
            <br />
            <a href='mailto:konsumentverket@konsumentverket.se' target='_blank'>
            konsumentverket@konsumentverket.se
            </a>
            <br />
            <a href='https:/konsumentverket.se' target='_blank' rel='noopener noreferrer'>
            konsumentverket.se 
            </a>"
          />

          <Accordion
            title="Integritetsskyddsmyndigheten"
            content="
            box 8114
            <br />
            104 20 Stockholm
            <br />
            <a href='tel:086576100' target='_blank'>
            08-657 61 00
            </a>
            <br />
            <a href='mailto:imy@imy.se' target='_blank' rel='noopener noreferrer'>
            imy@imy.se
            </a>
            <br />
            <a href='https://imy.se' target='_blank'>
            imy.se
            </a>"
          />
        </ContentContainer>
      </Section>
    </Layout>
  )
}

export default FormedlingPage
